<template>

<div class="up_content_inner up_add_new_site">
  <div class="up_main_container_sm">
      <div class="up_site_add_container">
          <div class="up_website_details">
              <div class="up_website_details_section up_service_type p-mb-5">
                  <h3>Email Setting</h3>
                  <div class="up_website_details_inner">
                      <div class="fullwidth-col">
                          <form @submit.prevent="updateSettingList()" :value="allWebsiteData.data">
                            <div class="single_select_box_list">
                              <div class="single_row_box col-mg-4">
                                <div class="single_box_top">
                                  <h5>Monthly Transactional Email</h5>
                                </div>
                                <div class="single_box_option_list">
                                  <div v-for="category of transEmailSettings" :key="category.key" class="field-radiobutton">
                                    <div class="p-field-radiobutton">
                                      <RadioButton :id="category.key" name="transEmailSettings" :value="category" v-model="selectedTransEmailSetting.data"  />
                                      <label :for="category.key">{{category.name}}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="single_row_box col-mg-4">
                                <div class="single_box_top">
                                  <h5>Hit Alert Email</h5>
                                </div>
                                <div class="single_box_option_list">
                                  <div v-for="category of hitEmailSettings" :key="category.key" class="field-radiobutton">
                                    <div class="p-field-radiobutton">
                                      <RadioButton :id="category.key" name="hitEmailSettings" :value="category" v-model="selectedHitEmailSetting.data" />
                                      <label :for="category.key">{{category.name}}</label>
                                    </div>
                                  </div>
                                </div>
                              </div> 
                              <div class="single_row_box col-mg-4">
                                <div class="single_box_top">
                                  <h5>Real Time Scan Email</h5>
                                </div>
                                <div class="single_box_option_list">
                                  <div v-for="category of realtimeEmailSettings" :key="category.key" class="field-radiobutton">
                                    <div class="p-field-radiobutton">
                                      <RadioButton :id="category.key" name="realtimeEmailSettings" :value="category" v-model="selectedRealtimeEmailSetting.data" />
                                      <label :for="category.key">{{category.name}}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="single_row_box col-mg-12">
                                <div class="single_box_option_list">
                                  <div class="sup_header_field_blank">
                                    <Button icon="pi pi-check" type="submit" label="Submit" class="p-button-primary p-mr-2" />
                                  </div>
                                  <div class="sup_header_field_blank">
                                    <Button icon="pi pi-refresh" label="Reset" class="p-button-info" @click="reloadPage()" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- Ntofication Message Showing Element Start -->
  <Toast position="top-right" />
  <!-- Ntofication Message Showing Element End -->
</div>
</template>


<script>
import {
  userPortalUserSettings,
  userPortalSettingsUpdate,
} from "../../../../config/appUrls";
import { apiKey } from "../../../../config/constant";
import axios from "axios";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { reactive, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "userSettingsManagement",
  setup() {
    const route = useRoute();
    const store = useStore();
    const toast = useToast();
    const allWebsiteData = reactive({
      data: null,
      userId: null,
    });
    const transEmailSettings = ref([
      {name: 'Yes', key: '1'}, 
      {name: 'No', key: '0'},     
    ]);
    const selectedTransEmailSetting = reactive({
      data: null,
    });
    const hitEmailSettings = ref([
        {name: 'Yes', key: '1'}, 
        {name: 'No', key: '0'}, 
    ]);
    const selectedHitEmailSetting = reactive({
      data: null,
    });
    const realtimeEmailSettings = ref([
      {name: 'Yes', key: '1'}, 
      {name: 'No', key: '0'},
    ]);
    const selectedRealtimeEmailSetting = reactive({
      data: null,
    });
    function reloadPage() {
      window.location.reload();
    }

    onBeforeMount(async () => {
      allWebsiteData.userId = route.params.id;
      fetchSettingList();
    });

    //Customer list for new customer search dropdown
    const fetchSettingList = async () => {
      const response = await axios.get(userPortalUserSettings, {
        params: {},
        headers: {
          apiKey: apiKey,
          token: store.getters.adminAuthToken,
        },
      });

      if (response.data.status === 200) {
        if (response.data.data === null) {
          selectedTransEmailSetting.data = ref(transEmailSettings.value[0]);
          selectedHitEmailSetting.data = ref(hitEmailSettings.value[0]);
          selectedRealtimeEmailSetting.data = ref(realtimeEmailSettings.value[0]);
        } else {
          allWebsiteData.data = response.data.data;
          if (response.data.data.transactionalEmail === true) {
            selectedTransEmailSetting.data = ref(transEmailSettings.value[0]);
          } else {
            selectedTransEmailSetting.data = ref(transEmailSettings.value[1]);
          }
          if (response.data.data.hitAlertEmail === true) {
            selectedHitEmailSetting.data = ref(hitEmailSettings.value[0]);
          } else {
            selectedHitEmailSetting.data = ref(hitEmailSettings.value[1]);
          }
          if (response.data.data.realtimeScanEmail === true) {
            selectedRealtimeEmailSetting.data = ref(realtimeEmailSettings.value[0]);
          } else {
            selectedRealtimeEmailSetting.data = ref(realtimeEmailSettings.value[1]);
          }
        }
      }
    };

    const updateSettingList = async () => {
      try {
        let options = {
          method: "post",
          url: userPortalSettingsUpdate,
          params: {},
          data: JSON.stringify({
            transactionalEmail: selectedTransEmailSetting.data.key,
            hitAlertEmail: selectedHitEmailSetting.data.key,
            realtimeScanEmail: selectedRealtimeEmailSetting.data.key,
            uuid: allWebsiteData.data === null ? "" : allWebsiteData.data.uuid,
          }),
          headers: {
            "Content-Type": "application/json",
            apiKey: apiKey,
            token: store.getters.adminAuthToken,
          },
          transformRequest: [
            (data) => {
              // transform the data
              return data;
            },
          ],
        };
        const res = await axios(options);
        if (res.data.status === 200) {
          toast.add({
            severity: "success",
            summary: "Settings Updated!",
            detail: "Settings Updated successfully.",
          });
          reloadPage();
        }
      } catch (error) {
        console.log(error);
      }
    };

    return {
      allWebsiteData,
      fetchSettingList,
      reloadPage,
      updateSettingList,
      transEmailSettings, 
      selectedTransEmailSetting,
      hitEmailSettings, 
      selectedHitEmailSetting,
      realtimeEmailSettings, 
      selectedRealtimeEmailSetting
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/component/userportal/pages/addNewSite/AddNewSite';

</style>
